@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.card-offer {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: 1px;
  @include fixedSize(108px, max-content);
  background: var(--cst-cards-item-offer-background);
  outline: 2px solid var(--cst-cards-item-offer-outline);
  outline-offset: -2px;
  box-shadow: var(--elevation-1);
  overflow: hidden;
  border-radius: var(--cornerradius12);
  transition: all 0.2s ease;
  @include min-1440-break {
    @include fixedSize(138px, max-content);
    padding: 0px 9px 9px 9px;
  }

  &:active {
    background: var(--cst-cards-item-offer-background-pressed);
    outline: 2px solid var(--cst-cards-item-offer-outline-pressed);
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include singleFixedSize(100px);
    @include min-1440-break {
      @include singleFixedSize(120px);
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;

      @include singleFixedSize(100px);
      @include min-1440-break {
        @include singleFixedSize(120px);
      }
    }

    .item-image {
      z-index: 2;
      @include singleFixedSize(68px);
      @include min-1440-break {
        @include singleFixedSize(96px);
      }
    }

    .tags-info-container {
      position: absolute;
      z-index: 3;
      gap: 4px;
      display: flex;
      flex-direction: column;

      bottom: 7px;
      left: 7px;

      @include min-1440-break {
        bottom: 0px;
        left: 0px;
      }

      .tag-info {
        padding: 2px 6px;
        height: 21px;
        border-radius: var(--cornerradius8);
        @include min-1440-break {
          padding: 3px 8px;
          height: 24px;
          border-radius: var(--cornerradius8);
        }

        p {
          @include typography-xs;
          @include weight-medium;

          @include min-1440-break {
            @include typography-s;
          }
        }
      }
    }
  }

  .item-info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0px 7px 7px 7px;
    justify-content: center;
    @include fixedSize(100%, 42px);

    @include min-1440-break {
      padding: 0px;
      @include fixedSize(120px, 63px);
    }
    .prices {
      display: flex;
      gap: 6px;

      align-items: center;

      .current {
        @include typography-m;
        @include weight-bold;
        color: var(--cst-cards-offer-new-price-default);

        @include min-1440-break {
          @include typography-xl;
        }
      }

      .old {
        @include typography-xs;
        @include weight-semi-bold;
        color: var(--cst-cards-offer-old-price-default);
        text-decoration: line-through;

        @include min-1440-break {
          @include typography-s;
        }
      }
    }

    .title {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @include fixedSize(86px, 18px);
      @include typography-s;
      @include weight-medium;
      color: var(--cst-cards-offer-description-text-default);

      @include min-1440-break {
        @include typography-base;
        @include fixedSize(120px, 21px);
      }
    }
  }
}

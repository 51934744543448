@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-live-feed {
  display: flex;
  position: relative;
  justify-content: center;
  border-radius: var(--cornerradius12);
  background: var(--cst-cards-live-feed-background-default);
  outline: 2px solid var(--cst-cards-live-feed-outline-default);
  outline-offset: -2px;
  overflow: hidden;
  box-shadow: var(--elevation-1);
  transition: all 0.2s ease-in-out;

  cursor: pointer;
  @include fixedSize(100px, 136px);
  padding: 2px 9px 12px 9px;

  &:hover {
    padding: 10px 9px 12px 9px;
    outline: 2px solid var(--cst-cards-live-feed-outline-hover);
  }

  @include min-1440-break {
    @include fixedSize(138px, 162px);
    padding: 10px 12px 12px 12px;

    &:hover {
      padding: 10px 12px 12px 12px;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    gap: 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
      div.card-live-feed--content__info {
        opacity: 1;
        height: 100%;
        transform: rotate(0deg);
      }

      div.card-live-feed--content__item {
        opacity: 0;
      }
    }

    &__info {
      opacity: 0;
      flex-direction: column;
      transition: all 0.2s ease-in-out;
      height: 0;
      position: absolute;
      top: 7px;
      left: 7px;
      max-width: 86px;
      @include min-1440-break {
        max-width: 114px;
        top: 12px;
        left: 12px;
      }

      transform: translateY(-100px);
      .price-tags {
        display: flex;
        align-items: center;
        gap: 6px;

        &--old {
          @include typography-s;
          text-decoration: none;
          color: var(--cst-cards-live-feed-oldpricetext-hover);
          @include min-1440-break {
            @include typography-m;
            text-decoration: line-through;
          }
        }

        &--current {
          @include typography-m;
          color: var(--cst-cards-live-feed-newpricetext-hover);
          @include min-1440-break {
            @include typography-xl;
          }
        }
      }

      .title {
        max-height: 54px;
        @include typography-s;
        color: var(--cst-cards-live-feed-descripiontext-hover);
        @include min-1440-break {
          overflow: hidden;
          max-height: 64px;

          @include typography-base;
        }
      }
    }

    &__item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 12px;
      transition: all 0.2s ease-in-out;
      @include min-1440-break {
        top: initial;
      }
    }
  }

  &--tags {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0;
    width: max-content;
    border-radius: 6px;
    background: var(--cst-cards-live-feed-backgroundtags-default);

    gap: 6px;
    width: max-content;
    padding: 4px;

    @include min-1440-break {
      width: max-content;
      gap: 11px;
      padding: 6px 4px 6px 4px;
    }

    &-rarity {
      padding: 4px;

      @include min-1440-break {
        padding: 6px;
      }
    }
  }
}

.item-rarity {
  position: absolute;
  z-index: 0;
  &-adopt {
    padding: 10px 16.67px;
    @include singleFixedSize(100px);

    @include min-1440-break {
      padding: 12px 20px;
      @include singleFixedSize(120px);
    }
  }
  &-mm2 {
    padding: 10px 16.67px;
    @include singleFixedSize(120px);

    @include min-1440-break {
      padding: 12px 20px;
      @include singleFixedSize(150px);
    }
  }
}

.item {
  pointer-events: none;
  z-index: 1;
  @include singleFixedSize(68px);

  @include min-1440-break {
    @include singleFixedSize(96px);
  }
}

.tag-category {
  @include singleFixedSize(14px);

  @include min-1440-break {
    @include singleFixedSize(18px);
  }
}

@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .value-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    .value {
      @include typography-s;
      @include weight-semi-bold;

      &-positive {
        color: var(--cst-cards-checkout-info-discount);
      }

      &-negative {
        color: var(--cst-cards-checkout-info-price-increase);
      }
      &-neutral {
        color: var(--cst-cards-checkout-info-detatils);
      }

      @include min-1440-break {
        @include typography-base;
      }
    }
    .old-value {
      color: #9e9d9e;
      text-decoration: line-through;
      @include typography-s;
      @include weight-medium;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .decoration {
      color: var(--cst-cards-checkout-info-particle);
    }

    .title {
      color: var(--cst-cards-checkout-info-item);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100px;

      @include typography-s;
      @include weight-medium;

      @include min-1440-break {
        max-width: 150px;
        @include typography-base;
      }

      &-action {
        cursor: pointer;
        color: var(--cst-hyperlink-primary-title-default);

        &:hover {
          color: var(--cst-hyperlink-primary-title-hover);
        }
      }
    }
  }
}

@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-shopping-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 32px;

  .left-slot {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .items-container {
    display: flex;
    gap: 8px;
    width: 100%;
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .item-info {
    .action-btns {
      gap: 8px;
    }
    .content {
      .item {
        padding: 8px;
      }

      .properties {
        padding: 10px;
        gap: 10px;
        border-radius: 10px;
      }
    }
  }

  .item-info {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    height: 76px;
    justify-content: space-between;
    width: 100%;

    @include min-1440-break {
      height: 106px;
    }

    .content {
      display: flex;
      align-items: center;
      gap: 14px;
      width: 100%;

      .item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &.item-unavailable {
          opacity: 0.5;
        }

        .item-count {
          position: absolute;
          z-index: 11;
          right: 0;
          top: 8px;
        }

        .soldout-label {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
          opacity: 1;
          @include fixedSize(44px, 35px);
          top: 10px;

          &:hover {
            opacity: 1;
          }
          background: url('../../../../../../public/assets/items/sold-out-label.webp')
            top center no-repeat;
          background-size: contain;

          @include min-1440-break {
            @include fixedSize(60px, 48px);
            top: 17px;
          }
        }

        .item-variant {
          z-index: 1;
          pointer-events: none;
          @include singleFixedSize(48px);

          @include min-1440-break {
            @include singleFixedSize(68px);
          }
        }

        .background {
          position: absolute;
          z-index: 0;
          @include singleFixedSize(56.67px);

          @include min-1440-break {
            @include singleFixedSize(75px);
          }
        }
      }

      .title-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        width: 100%;

        .item-type {
          color: var(--cst-cards-shoping-item-title);
          text-transform: capitalize;
          @include typography-xs;
          @include weight-regular;

          @include min-1440-break {
            @include typography-base;
          }
        }

        .item-title {
          color: var(--cst-cards-shoping-item-subtitle);
          @include typography-base;
          @include weight-semi-bold;

          @include min-1440-break {
            @include typography-l;
          }
        }
      }

      .properties {
        display: flex;
        align-items: center;
        width: max-content;
        background: var(--cst-cards-shoping-item-background-tags);
      }
    }
  }

  .item-info {
    gap: 10px;
    .action-btns {
      gap: 6px;
    }
    .content {
      .item {
        height: 76px;

        @include min-1440-break {
          height: 106px;
        }
      }

      .properties {
        gap: 6px;
        padding: 6px;
        border-radius: 6px;

        @include min-1440-break {
          gap: 10px;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }

  .price-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .price {
      display: flex;
      height: 76px;
      flex-direction: column;
      justify-content: center;

      @include min-1440-break {
        height: 106px;
      }

      .current,
      .status {
        color: var(--cst-cards-shoping-item-new-price);

        @include typography-base;
        @include weight-bold;

        @include min-1440-break {
          @include typography-xl;
        }
      }

      .status {
        @include weight-semi-bold;
        @include typography-base;

        @include min-1440-break {
          @include typography-l;
        }
      }

      .old {
        color: var(--cst-cards-shoping-item-old-price);

        @include typography-s;
        @include weight-semi-bold;

        @include min-1440-break {
          @include typography-m;
        }
      }
    }
  }
}

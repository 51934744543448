@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;

.card-price-item-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  overflow: hidden;
  border-radius: var(--cornerradius10);
  box-shadow: 0 0 0 2px var(--cst-cards-item-page-background);
  @include fixedSize(100%, 79px);

  @include min-1440-break {
    @include fixedSize(100%, 91px);
  }

  .content {
    display: flex;
    flex-direction: column;
    .text {
      color: var(--cst-cards-item-page-description);
      @include typography-base;
      @include weight-medium;
    }
    .price-info {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 51px;

      @include min-1440-break {
        width: 64px;
      }

      &--old {
        color: var(--cst-cards-item-page-oldprice);
        @include typography-m;
        @include weight-semi-bold;

        @include min-1440-break {
          @include typography-xl;
        }
      }

      &--current {
        color: var(--cst-cards-item-page-newprice);

        @include typography-xl;
        @include weight-bold;

        @include min-1440-break {
          @include typography-3xl;
        }
      }
    }
  }
}

.background {
  position: relative;
  width: 196px;
  height: 196px;
  display: flex;
  justify-content: center;
  align-items: center;

  .card-decoration {
    position: absolute;
    z-index: 0;
    left: 88px;
    rotate: 15deg;
    bottom: 5px;
    opacity: 0.3;

    @include min-1440-break {
      left: 66px;
      bottom: 12px;
    }

    @include min-1920-break {
      left: 28px;
      bottom: 11px;
    }
  }
}
